import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";

import Page from "../components/page";
import Spacer from "../components/spacer";
import Link from "../components/link";
import Text from "../components/text";
import colors from "../components/colors";
import font from "../components/fonts";
import SocialMedia from "../components/social-media";

const Name = styled.h1`
  font-size: ${font.fontSize6};
  font-family: ${font.sans};
  font-weight: bold;
  margin: 0;
  letter-spacing: 2px;
  text-align: left;
  color: ${colors.black};
`;


export default () => {
  return (
    <Page title={<Name as="h1">Adrian Kopczewski</Name>}>
      <>
        <Text align="left">
          I'm a Front End Engineer working at{" "}
          <Link
            target="_blank"
            rel="noreferrer noopener"
            href="https://designory.com"
          >
            designory
          </Link>
          <Spacer top={2} />
          Passionate about e-commerce, functional programming, scalability, security and business.
          <Spacer top={2} />
          I also build cool stuff under {" "}
          <Link
            target="_blank"
            rel="noreferrer noopener"
            href="https://digitallofts.com"
          >
            digitallofts
          </Link>
        </Text>
        <SocialMedia />
      </>
    </Page>
  );
};

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
