import React from "react";
import styled from "styled-components";

import Spacer from "./spacer";
import Twitter from "./../svgs/twitter";
import Github from "./../svgs/github";
import colors from "./colors";

const InlineLogoWrapper = styled.span`
  display: flex;
  align-items: center;
  width: 100%;
`;

const Icon = ({ svg: Svg, size, bg, href }) => (
  <a target="_blank" rel="noreferrer noopener" href={href}>
    <Logo size={size} color={bg}>
      <Svg size={size} fill={colors.black}></Svg>
    </Logo>
  </a>
);

const Logo = styled.div`
  width: ${props => (props.size ? `${props.size}px` : "40px")};
  height: ${props => (props.size ? `${props.size}px` : "40px")};
  padding: 8px;
  border-radius: 4px;
  cursor: pointer;
  transition: all 100ms ease-in;

  &:hover {
    background: ${props => props.color};
  }
`;

const Square = styled.div`
  width: 20px;
  height: 20px;
  background: #db4d3f;
  position: relative;
`;

export const GithubIcon = ({ href }) => (
  <Icon href={href} svg={Github} bg="rgba(24, 23, 23, 0.1)" />
);

export const TwitterIcon = ({ href, size, inline = false }) => (
  <Icon svg={Twitter} href={href} bg="rgba(29, 161, 242, 0.2)" size={size} />
);

export default () => (
  <InlineLogoWrapper>
    <TwitterIcon href="https://github.com/akopcz2" />
    <Spacer left={2} />
    <GithubIcon href="https://github.com/akopcz2" />
    <Spacer left={2} />
  </InlineLogoWrapper>
);
